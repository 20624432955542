import React, {useContext} from "react";
import styled from "@emotion/styled";
import vehicleIconResolver from "../../vehicles/VehicleIconResolver";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {renderDriverName, renderTime} from "./DriverTitle";
import {DriverLocation, Manifest, ManifestDriver} from "../../../generated/graphql";
import {Tooltip2} from "@blueprintjs/popover2";
import {differenceInMilliseconds} from "date-fns";
import {DriverCords, DriverIconProps} from "./DriverMarker";
import {Text} from "@blueprintjs/core";
import {PreferenceContext} from "../../../providers/PreferenceProvider";

export interface DriverMarkerV2Props {
  manifest: Manifest;
  color?: string;
  cords: DriverCords;
  lat: any;
  lng: any;
  isSelected: boolean;
  isShowVehicles: boolean;
  isShowCode: boolean;
  onClick?(manifestDriverId: number): void;
  handleDriverSingleClick: (e: React.MouseEvent<HTMLElement | SVGSVGElement>, manifest: Manifest) => void;
  handleDriverDoubleClick: (
    e: React.MouseEvent<HTMLElement | SVGSVGElement>,
    manifest: Manifest,
    isSelected: boolean
  ) => void;
  usePortal?: boolean;
  newWindow?: Window;
}

type DriverTitleV2Props = {
  driver: ManifestDriver;
  isShowCode: boolean;
};

export enum GPSStatusColor {
  GREEN = "#32A467",
  YELLOW = "#FFC701",
  RED = "#FA545E",
  GREY = "#999999"
}

export enum GPSStatusType {
  NEW,
  RECENT,
  STALE,
  OLD
}

export const getGPSStatusType = (driverCords: DriverCords | DriverLocation) => {
  const differenceInMinutes = differenceInMilliseconds(Date.now(), new Date(driverCords.fixDateTime)) / (60 * 1000);
  if (differenceInMinutes <= 5) {
    return GPSStatusType.NEW;
  } else if (differenceInMinutes <= 15) {
    return GPSStatusType.RECENT;
  }
  if (differenceInMinutes <= 60) {
    return GPSStatusType.STALE;
  }
  return GPSStatusType.OLD;
};

export const getGPSColor = (driverCords: DriverCords | DriverLocation): GPSStatusColor => {
  const gpsStatusType = getGPSStatusType(driverCords);
  switch (gpsStatusType) {
    case GPSStatusType.NEW:
      return GPSStatusColor.GREEN;
    case GPSStatusType.RECENT:
      return GPSStatusColor.YELLOW;
    case GPSStatusType.STALE:
      return GPSStatusColor.RED;
    default:
      return GPSStatusColor.GREY;
  }
};

export const renderShortName = (name: string | null | undefined) => {
  if (name) {
    const nameWords = name.trim().split(" ");
    if (nameWords.length === 1) {
      return nameWords[0].charAt(0).toUpperCase();
    }
    if (nameWords.length >= 2) {
      return `${nameWords[0].charAt(0).toUpperCase()}${nameWords[1].charAt(0).toUpperCase()}`;
    }
  }
  return "";
};

const DriverTitleV2 = ({driver, isShowCode}: DriverTitleV2Props) => {
  return (
    <TooltipTick>
      <DriverTitle>{isShowCode && driver.code ? driver.code : renderDriverName(driver)}</DriverTitle>
    </TooltipTick>
  );
};

const DriverMarkerV2: React.FC<DriverMarkerV2Props> = ({
  manifest,
  color,
  onClick,
  isSelected,
  cords,
  isShowVehicles,
  isShowCode,
  handleDriverSingleClick,
  handleDriverDoubleClick,
  usePortal,
  newWindow
}) => {
  const {tenantPreferences} = useContext(PreferenceContext);
  const resolvedIcon = vehicleIconResolver(manifest.vehicleType.description, tenantPreferences);

  return (
    <Marker
      isShowDrivers={isShowVehicles}
      data-testid={`driver-marker-${manifest.manifestDriverId}`}
      onClick={onClick ? () => onClick(manifest.manifestDriverId) : undefined}
    >
      <GPSStatusContainer isAbsolute={isShowVehicles}>
        <Tooltip2
          popoverClassName="map-action-popover driver-status-popover"
          position={isShowVehicles ? "right" : "top"}
          content={renderTime(cords)}
          usePortal={usePortal}
          portalContainer={newWindow?.document.body}
        >
          <GPSStatus data-testid={`driver-marker-status-${manifest.manifestDriverId}`} color={getGPSColor(cords)} />
        </Tooltip2>
      </GPSStatusContainer>
      {isShowVehicles && <DriverTitleV2 driver={manifest.driver} isShowCode={isShowCode} />}
      {isShowVehicles ? (
        <DriverIcon
          data-testid={"driver-icon"}
          size={"xl"}
          icon={resolvedIcon}
          isSelected={isSelected}
          color={color}
          fillOpacity={100}
          onClick={(e) => handleDriverSingleClick(e, manifest)}
          onDoubleClick={(e) => handleDriverDoubleClick(e, manifest, isSelected)}
        />
      ) : (
        <DriverMarkerContent
          data-testid={"driver-marker-content"}
          isSelected={isSelected}
          color={color}
          onClick={(e) => handleDriverSingleClick(e, manifest)}
          onDoubleClick={(e) => handleDriverDoubleClick(e, manifest, isSelected)}
        >
          <Text ellipsize>{isShowCode ? manifest.driver.code : renderShortName(manifest.driver.name)}</Text>
        </DriverMarkerContent>
      )}
    </Marker>
  );
};

export default DriverMarkerV2;

const Marker = styled.div<{isShowDrivers?: boolean}>`
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: ${(props) => (props.isShowDrivers ? "32px" : "fit-content")};
  height: ${(props) => (props.isShowDrivers ? "32px" : "fit-content")};
  border-radius: ${(props) => (!props.isShowDrivers ? "6px" : "50%")};
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  padding: ${(props) => (!props.isShowDrivers ? "4px 8px" : "")};
  gap: ${(props) => (!props.isShowDrivers ? "4px" : "")};
  max-width: ${(props) => (!props.isShowDrivers ? "100px" : "")};

  cursor: pointer;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const GPSStatus = styled.div<{color: string}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffffff;
`;

const GPSStatusContainer = styled.div<{isAbsolute?: boolean}>`
  position: ${(props) => (props.isAbsolute ? "absolute" : "relative")};
  bottom: 0;
  right: ${(props) => (props.isAbsolute ? "-4px" : "0")};
`;

const DriverIcon = styled(FontAwesomeIcon)<DriverIconProps>`
  color: ${(props) => (props.isSelected ? "#436BF8" : props.color ?? "#161616")};
  --fa-primary-color: #ffffff;
  --fa-secondary-color: ${(props) => (props.isSelected ? "#436BF8" : props.color ?? "#161616")};
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
`;

const DriverMarkerContent = styled.span<DriverIconProps>`
  color: ${(props) => (props.isSelected ? "#436BF8" : props.color ?? "#1C2127")};
  overflow: hidden;
`;

const TooltipTick = styled.div`
  position: absolute;
  padding: 2px 5px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  top: -25px;

  &:after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: calc(50% - 4px);

    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #ffffff;
  }
`;

const DriverTitle = styled.div`
  white-space: nowrap;
`;

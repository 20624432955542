import React, {useCallback, useContext, useMemo, useState} from "react";
import {NoBackGroundSettingEditorButton, SettingEditorButton} from "../components/SettingItemEditor";
import styled from "@emotion/styled";
import {ServiceTypeColorContext, ServiceTypeColorDispatchContext} from "./ServiceTypeColorStateProvider";
import {useSetTenantPrefMutation} from "../../../generated/graphql";
import {PreferenceContext, extractSimplePref} from "../../../providers/PreferenceProvider";
import {ServiceTypeColorType} from "./ServiceTypeColor";
import {TenantPreferences} from "../../common/Constants";
import {UserColor} from "../ColorizedIndicators/types/indicatorLogicType";
import {Button, Spinner, Text} from "@blueprintjs/core";
import {ConfirmationDialog} from "../../common/ConfirmationDialog";
import {showToastWithTitle} from "../../../utils/ToasterWithTitle";

type UpdateHandlerType = "save" | "remove";

const ActionContainer = () => {
  const {selectedServices, selectedColor, isDataChanged, confirmWithoutSaving} = useContext(ServiceTypeColorContext);
  const serviceTypeColorDispatch = useContext(ServiceTypeColorDispatchContext);
  const {tenantPreferences, tenantPrefsQueryRefetch} = useContext(PreferenceContext);
  const [setTenantPref] = useSetTenantPrefMutation();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [loadingType, setLoadingType] = useState<UpdateHandlerType | undefined>(undefined);

  const isShowRemovebtn = useMemo(() => selectedServices.some((service) => service.color), [selectedServices]);

  const updateHandler = useCallback(
    (type: UpdateHandlerType) => {
      setLoadingType(type);
      let newValue = extractSimplePref(tenantPreferences, TenantPreferences.serviceTypeColor, [])
        .value as ServiceTypeColorType[];
      if (type === "remove") {
        const removeServices = selectedServices.map((item) => item.name);
        newValue = newValue.filter((item) => !removeServices.includes(item.name));
      } else {
        selectedServices.forEach((service) => {
          service.color = selectedColor as UserColor;
          const existingItemIndex = newValue.findIndex((item) => item.name === service.name);
          if (existingItemIndex !== -1) {
            newValue[existingItemIndex] = service;
          } else {
            newValue.push(service);
          }
        });
      }
      setTenantPref({
        variables: {
          name: TenantPreferences.serviceTypeColor,
          input: {
            value: JSON.stringify({value: newValue})
          }
        }
      })
        .then(() => {
          if (tenantPrefsQueryRefetch) tenantPrefsQueryRefetch();
        })
        .catch((error: Error) => {
          console.error(error);
        })
        .finally(() => {
          if (confirmWithoutSaving.redirectFunc && confirmWithoutSaving.redirectParams) {
            confirmWithoutSaving.redirectFunc(confirmWithoutSaving.redirectParams);
          }
          showToastWithTitle({
            intent: "success",
            title: "Success",
            message: `Selected Service Type(s) Colors had been ${type === "remove" ? "removed" : "saved"}`
          });
          serviceTypeColorDispatch({type: "RESET_STATE"});
          setLoadingType(undefined);
          setShowConfirm(false);
        });
    },
    [
      tenantPreferences,
      setTenantPref,
      selectedServices,
      selectedColor,
      tenantPrefsQueryRefetch,
      confirmWithoutSaving,
      serviceTypeColorDispatch
    ]
  );

  const cancelHandler = useCallback(() => {
    serviceTypeColorDispatch({type: "RESET_STATE"});
  }, [serviceTypeColorDispatch]);

  return (
    <Container>
      <ConfirmationDialog
        icon={<WarningIcon />}
        testIdPrefix="remove-service-type-color"
        isVisible={showConfirm}
        header="Remove Color?"
        body="Are you sure you want to remove Service Type Color assigned to selected Service Type(s)?"
        yesText={
          loadingType === "remove" ? (
            <div style={{width: "80px"}}>
              <Spinner size={20} />
            </div>
          ) : (
            "Yes, Remove"
          )
        }
        handleYesClicked={() => updateHandler("remove")}
        handleClose={() => setShowConfirm(false)}
      />
      <ConfirmationDialog
        testIdPrefix="confirm-without-saving-service-type-color"
        isVisible={confirmWithoutSaving.value}
        header="Leave Without Saving?"
        body={"You have unsaved adjustments that will be lost if you Leave this page without saving."}
        noText="Leave Without Saving"
        yesText={<Text style={{color: "#32A467"}}>Save and Leave</Text>}
        handleYesClicked={() => updateHandler("save")}
        handleClose={() => serviceTypeColorDispatch({type: "SHOW_CONFIRM_WITHOUT_SAVING", payload: {value: false}})}
        handleNoClicked={() => {
          if (confirmWithoutSaving.redirectFunc && confirmWithoutSaving.redirectParams) {
            confirmWithoutSaving.redirectFunc(confirmWithoutSaving.redirectParams);
          }
          serviceTypeColorDispatch({type: "RESET_STATE"});
        }}
      />
      <ActionsGroup>
        {isShowRemovebtn && (
          <RemoveButton onClick={() => setShowConfirm(true)} data-testid="service-color-remove">
            Remove Color
          </RemoveButton>
        )}
      </ActionsGroup>
      <ActionsGroup>
        <NoBackGroundSettingEditorButton
          disabled={selectedServices.length === 0}
          onClick={cancelHandler}
          data-testid="service-color-cancel"
          key="cancel"
        >
          Cancel
        </NoBackGroundSettingEditorButton>
        <SettingEditorButton
          disabled={!isDataChanged}
          onClick={() => updateHandler("save")}
          data-testid="service-color-save"
          key="save"
          loading={loadingType === "save"}
        >
          Save
        </SettingEditorButton>
      </ActionsGroup>
    </Container>
  );
};

export default ActionContainer;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 200px);
  height: 70px;
  padding: 0 25px;
  box-shadow: 10px 7px 20px 0px #00000059;
`;

const ActionsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
`;

const RemoveButton = styled(Button)`
  color: #fa545e !important;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) !important;
`;

const WarningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_2270_7460)">
        <path
          d="M11 1.375C11.6102 1.375 12.173 1.69727 12.4824 2.22578L21.7637 18.0383C22.0773 18.5711 22.0773 19.2285 21.7723 19.7613C21.4672 20.2941 20.8957 20.625 20.2813 20.625H1.71875C1.1043 20.625 0.532813 20.2941 0.227735 19.7613C-0.0773428 19.2285 -0.0730459 18.5668 0.236329 18.0383L9.51758 2.22578C9.82695 1.69727 10.3898 1.375 11 1.375ZM11 6.875C10.4285 6.875 9.96875 7.33477 9.96875 7.90625V12.7188C9.96875 13.2902 10.4285 13.75 11 13.75C11.5715 13.75 12.0313 13.2902 12.0313 12.7188V7.90625C12.0313 7.33477 11.5715 6.875 11 6.875ZM12.375 16.5C12.375 16.1353 12.2301 15.7856 11.9723 15.5277C11.7144 15.2699 11.3647 15.125 11 15.125C10.6353 15.125 10.2856 15.2699 10.0277 15.5277C9.76987 15.7856 9.625 16.1353 9.625 16.5C9.625 16.8647 9.76987 17.2144 10.0277 17.4723C10.2856 17.7301 10.6353 17.875 11 17.875C11.3647 17.875 11.7144 17.7301 11.9723 17.4723C12.2301 17.2144 12.375 16.8647 12.375 16.5Z"
          fill="#FA545E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2270_7460">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import {AgGridReact} from "@ag-grid-community/react";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import {useGridRefreshOnInterval} from "../../hooks/useGridRefreshOnInterval";
import {ApolloError, ApolloQueryResult, useApolloClient} from "@apollo/client";
import {
  ColDef,
  ColumnMovedEvent,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ITextFilterParams,
  ModuleRegistry,
  RowClassParams,
  RowStyle,
  SetFilterValuesFuncParams,
  SortChangedEvent,
  ValueGetterParams
} from "@ag-grid-community/core";
import {Stop, StopFilter, useGetDistinctValuesLazyQuery, useSetUserPrefMutation} from "../../../generated/graphql";
import {createStopsServerSideDatasource} from "./StopsServerSideDataSource";
import {useAuthState} from "../../AuthProvider";
import {
  ColorIndicatorCellRenderer,
  getColorPriority,
  getWarningMessage,
  GridContext,
  WARNING_MESSAGES,
  WarningText
} from "../../job/JobPanel";
import {DispatchRulesDataContext, DispatchRulesDataState} from "../../common/DispatchRulesDataProvider";
import {
  createJsonPref,
  extractJsonPref,
  extractSimplePref,
  PreferenceContext
} from "../../../providers/PreferenceProvider";
import DateFilter from "../../common/DateFilter";
import DoubleFieldCellRenderer, {DoubleFieldCellValue} from "../../common/cell-renderers/DoubleFieldCellRenderer";
import {ALLOWED_SAVED_PREFERENCE_SOURCES, TenantPreferences, UserPreferences} from "../../common/Constants";

import {useAppContext} from "../../../ApplicationContext";
import ServiceTypeCellRenderer from "../../common/cell-renderers/ServiceTypeCellRenderer";
import {getFilterValues} from "../../../services/AgGridService";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import StopStatusRenderer from "../../manifest/details/StopStatusRenderer";
import {jobStopStatusValueFormatter} from "../../manifest/details/ManifestDetails.service";
import {StopTypeIconCellRenderer} from "../../manifest/details/ManifestDetailsV2";
import {JobStopStatus, JobStopType, jobStopTypeToString} from "../../../services/ManifestStopService";
import {ServiceTypeFilter} from "../../manifest/details/components/ServiceFilter";
import {JobPiecesOrWeightCellRenderer} from "../../common/cell-renderers/JobPiecesAndWeightCellRenderer";
import {DetailsCellRenderer} from "../../common/cell-renderers/DetailsCellRenderer";
import {ContactCellRenderer, ContactValueGetter} from "../../common/cell-renderers/ContactCellRenderer";
import styled from "@emotion/styled";
import {getStopTimeCellValue, StopDateTimeType, TimeCellRenderer} from "../../common/cell-renderers/TimeCellRenderer";
import {JobNumberCellRenderer} from "../../common/cell-renderers/JobNumberCellRenderer";
import {getHighestRankedColor} from "../../manifest/ManifestCardCommon";
import {DispatchStationDataContext} from "../../common/DispatchStationDataProvider";
import {ConditionNames} from "../../settings/ColorizedIndicators/RuleGenerator.service";
import {ManifestFilterType} from "../../manifest/ManifestFilterState";
import {JobAssignmentViewState} from "../../../views/JobAssignmentViewReducer";
import CustomerFilter from "../../common/CustomerFilter";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";
import ColorCellRenderer from "../../common/cell-renderers/ColorCellRenderer";
import {CircleWarningIcon} from "../../manifest/details/components/JobColHeaderRenderer";
import {Icon, Text} from "@blueprintjs/core";
import {getArrayFromField} from "../../../utils/General";
import {isEqual} from "lodash";
import {LinkCellRenderer} from "../../common/cell-renderers/LinkCellRenderer";
import {TManifestDetailsDrawerOpen} from "../../../views/UnassignedJobsView";
import ActiveFilterDialog, {IActiveFilterDialogProps, IActiveFilterDialogRef} from "../../common/ActiveFilterDialog";
import GridSettingsToolPanel from "../../common/GridSettingsToolPanel";

interface IGridProps {
  assignmentViewState: JobAssignmentViewState;
  setGridHasActiveFilters: (value: boolean) => void;
  onManifestDetailsDrawerOpen: TManifestDetailsDrawerOpen;
}

export interface AgStop extends Stop {
  indicatorColor?: string;
  manifestStatus?: string;
}

type ExtendedAgStop = AgStop & {hide: boolean};

const ColumnTypes: {[key: string]: ColDef<AgStop>} = {
  filterColumn: {
    suppressMenu: false,
    menuTabs: ["filterMenuTab"],
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true
    }
  },
  textFilter: {
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ["contains", "notContains", "equals", "notEqual", "blank", "notBlank"]
    } as ITextFilterParams
  },
  numberFilter: {
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: [
        "equals",
        "notEqual",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
        "inRange"
      ]
    }
  },
  setFilter: {
    filter: "agSetColumnFilter"
  },
  dateFilter: {filter: DateFilter, filterParams: {type: "range"}}
};

const NotSortAndFilterColdef: ColDef = {sortable: false, type: "none"};

const DefaultColDef: ColDef = {
  resizable: true,
  suppressMenu: true,
  autoHeight: true,
  sortable: true,
  headerClass: "header-cell",
  cellClass: "body-cell",
  minWidth: 55,
  initialWidth: 90
};

const PinLeftColDef: ColDef = {
  lockPosition: "left",
  pinned: "left",
  lockPinned: true,
  suppressMovable: true,
  resizable: true
};

const gridOptions = {
  getRowClass: (params: RowClassParams) => {
    if ((params.node.data as AgStop & {hide: boolean})?.hide) {
      return "hide-row";
    }
  }
};

const TextFilter = ["filterColumn", "textFilter"];
const NumberFilter = ["filterColumn", "numberFilter"];

export interface IGridRef {
  onSearch: (query: string) => void;
  onToggleToolPane: (key: string) => void;
}

ModuleRegistry.registerModules([ServerSideRowModelModule, SetFilterModule, ColumnsToolPanelModule]);

const Grid = forwardRef<IGridRef, IGridProps>(
  ({assignmentViewState, setGridHasActiveFilters, onManifestDetailsDrawerOpen}, ref) => {
    const apolloClient = useApolloClient();
    const authState = useAuthState();
    const {appState} = useAppContext();
    const [setUserPref] = useSetUserPrefMutation();

    const {tenantPreferences, userPrefsQueryRefetch, userPreferences} = useContext(PreferenceContext);
    const ruleContextState = useContext<DispatchRulesDataState>(DispatchRulesDataContext);
    const dispatchStationState = useContext(DispatchStationDataContext);
    const {colorSort: filterAndSortByColorsEnabled} = useContext(FeatureFlagContext);

    const [getDistinctValues] = useGetDistinctValuesLazyQuery();

    const gridRef = useRef<AgGridReact<AgStop>>(null);
    const [gridReady, setGridReady] = useState<boolean>(false);
    const activeFilterDialogRef = useRef<IActiveFilterDialogRef>(null);

    const [datasource, setDatasource] = useState<IServerSideDatasource>();
    const [datasourceFailed, setDatasourceFailed] = useState<ApolloError | undefined>();

    const [gridContext, setGridContext] = useState<GridContext>({
      globalQuery: ""
    });

    const [warningMessage, setWarningMessage] = useState<WARNING_MESSAGES | undefined>();
    const [initializing, setInitializing] = useState<boolean>(true);

    useGridRefreshOnInterval("Assigned Stops", gridRef, gridReady, datasourceFailed);

    const isTimezoneOfJobStop = extractSimplePref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false)
      .value as boolean;

    const DateTimeValueGetter = useCallback(
      (params: ValueGetterParams<any>, typeOfDateTime: StopDateTimeType) => {
        return getStopTimeCellValue({
          data: params.data,
          is24HourFormat: appState.isTimeFormat24hr,
          isTimezoneOfJobStop: isTimezoneOfJobStop,
          typeOfDateTime: typeOfDateTime
        });
      },
      [appState.isTimeFormat24hr, isTimezoneOfJobStop]
    );

    const onManifestCellClicked = useCallback(
      (data: Stop) => {
        if (!data.manifest?.manifestDriverId || !data.driver?.driverId) return;
        onManifestDetailsDrawerOpen(data.manifest?.manifestDriverId, data.driver?.driverId);
      },
      [onManifestDetailsDrawerOpen]
    );

    const colorColDefs: ColDef[] = useMemo(() => {
      return filterAndSortByColorsEnabled
        ? [
            {
              field: "indicatorColor",
              headerName: "Color",
              suppressColumnsToolPanel: true,
              headerClass: "header-cell header-cell-color",
              cellClass: "body-cell body-cell-color",
              initialWidth: 60,
              minWidth: 18,
              cellRenderer: ColorIndicatorCellRenderer,
              type: "filterColumn",
              filter: "agSetColumnFilter",
              filterParams: {
                cellRenderer: ColorCellRenderer,
                values: ["No Color", ...(ruleContextState.rules ?? []).map((rule) => rule.event.params?.color)],
                suppressMiniFilter: true
              },
              ...PinLeftColDef
            }
          ]
        : [
            {
              field: "indicatorColor",
              headerName: "Color",
              headerClass: "header-cell header-cell-color",
              cellClass: "body-cell body-cell-color",
              initialWidth: 18,
              minWidth: 18,
              cellRenderer: ColorIndicatorCellRenderer,
              ...PinLeftColDef,
              resizable: false
            }
          ];
    }, [filterAndSortByColorsEnabled, ruleContextState.rules]);

    const columnDefs: ColDef<Stop>[] = useMemo(
      () =>
        [
          ...colorColDefs,
          {
            field: "driver.name",
            tooltipField: "driver.name",
            headerName: "Driver Name",
            initialWidth: 150,
            type: TextFilter,
            ...PinLeftColDef
          },
          {
            field: "manifest.manifestDriverId",
            tooltipField: "manifest.manifestDriverId",
            headerName: "Manifest #",
            cellClass: "body-cell text-blue text-bold",
            cellRenderer: LinkCellRenderer<Stop>,
            cellRendererParams: {
              minimal: true,
              fontSize: 14,
              color: "#2563EB",
              onClick: onManifestCellClicked
            },
            initialWidth: 120,
            type: NumberFilter,
            ...PinLeftColDef
          },
          {
            field: "job.jobNumber",
            headerName: "Job #",
            initialWidth: 100,
            cellRenderer: JobNumberCellRenderer,
            type: TextFilter,
            cellRendererParams: {
              minimal: true,
              fontSize: 14,
              color: "#2563EB"
            },
            ...PinLeftColDef
          },
          {
            field: "jobStopStatus",
            headerName: "Status",
            cellRenderer: StopStatusRenderer,
            cellStyle: {"--ag-cell-horizontal-padding": "4px", display: "flex", "justify-content": "center"},
            type: ["filterColumn", "setFilter"],
            filterParams: {
              values: Object.values(JobStopStatus),
              valueFormatter: jobStopStatusValueFormatter
            }
          },
          {
            field: "stopType",
            headerName: "Type",
            initialWidth: 70,
            cellRenderer: StopTypeIconCellRenderer,
            type: ["filterColumn", "setFilter"],
            filterParams: {
              values: Object.values(JobStopType),
              valueFormatter: jobStopTypeToString
            }
          },
          {
            field: "scheduledDateTime",
            headerName: "Scheduled Time",
            initialWidth: 150,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.SCHEDULED_TIME);
            },
            type: ["filterColumn", "dateFilter"]
          },
          {
            field: "address",
            headerName: "Address",
            initialWidth: 150,
            cellRenderer: DoubleFieldCellRenderer,
            valueGetter: (params) => {
              return {
                upperField: {
                  value: params.data.address,
                  tooltip: params.data.address
                },
                lowerField: {
                  value: `${params.data.city}, ${params.data.state}, ${params.data.zip}`,
                  tooltip: `${params.data.city}, ${params.data.state}, ${params.data.zip}`,
                  style: {
                    color: "#6B7280"
                  }
                }
              } as DoubleFieldCellValue;
            },
            type: TextFilter
          },
          {
            field: "dispatchZone",
            headerName: "Zone",
            tooltipField: "dispatchZone",
            type: TextFilter
          },
          {
            field: "job.service",
            headerName: "SVT",
            initialWidth: 100,
            cellRenderer: ServiceTypeCellRenderer,
            type: ["filterColumn"],
            filter: ServiceTypeFilter
          },
          {
            field: "job.pieces",
            headerName: "Pieces",
            cellRenderer: JobPiecesOrWeightCellRenderer,
            type: NumberFilter
          },
          {
            field: "job.weight",
            headerName: "Weight",
            cellRenderer: JobPiecesOrWeightCellRenderer,
            type: NumberFilter
          },
          {
            field: "name",
            tooltipField: "name",
            headerName: "Stop Name",
            initialWidth: 120,
            type: TextFilter
          },
          {
            field: "podName",
            tooltipField: "podName",
            headerName: "POD Name",
            initialWidth: 120,
            type: TextFilter
          },
          {
            field: "podDateTime",
            headerName: "POD Date",
            initialWidth: 120,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.POD_TIME);
            },
            type: ["filterColumn", "dateFilter"]
          },
          {
            field: "arriveDateTime",
            headerName: "Arrival Time",
            initialWidth: 120,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.ARRIVAL_TIME);
            },
            type: ["filterColumn", "dateFilter"]
          },
          {
            field: "departDateTime",
            headerName: "Departure Time",
            initialWidth: 150,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.DEPARTURE_TIME);
            },
            type: ["filterColumn", "dateFilter"]
          },
          {
            field: "lateDateTime",
            headerName: "Late Time",
            initialWidth: 120,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.LATE_TIME);
            },
            type: ["filterColumn", "dateFilter"]
          },
          {
            field: "earlyDateTime",
            headerName: "Early Time",
            initialWidth: 120,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.EARLY_TIME);
            },
            type: ["filterColumn", "dateFilter"]
          },
          {
            colId: "completedTime",
            headerName: "Completed Time",
            initialWidth: 120,
            cellRenderer: TimeCellRenderer,
            valueGetter: (params) => {
              return DateTimeValueGetter(params, StopDateTimeType.COMPLETED_TIME);
            },
            ...NotSortAndFilterColdef
          },
          {
            field: "job.routeNumber",
            tooltipField: "job.routeNumber",
            headerName: "Route Number",
            initialWidth: 150,
            type: TextFilter
          },
          {
            field: "job.site.code",
            tooltipField: "job.site.code",
            headerName: "Site",
            type: ["filterColumn"],
            filter: "agSetColumnFilter",
            filterParams: {
              values: (params: SetFilterValuesFuncParams) => {
                getFilterValues(params, getDistinctValues, "ng_site", "code.keyword");
              }
            }
          },
          {
            field: "order.customer.name",
            tooltipField: "order.customer.name",
            headerName: "Customer Account",
            initialWidth: 170,
            type: "filterColumn",
            filter: CustomerFilter
          },
          {
            field: "order.customer.customerCode",
            tooltipField: "order.customer.customerCode",
            headerName: "Account #",
            initialWidth: 120,
            type: TextFilter
          },
          {
            field: "order.auth",
            tooltipField: "order.auth",
            headerName: "Order Auth",
            initialWidth: 120,
            type: TextFilter
          },
          {
            field: "order.alias",
            tooltipField: "order.alias",
            headerName: "Order Alias",
            initialWidth: 120,
            type: TextFilter
          },
          {
            field: "order.caller",
            tooltipField: "order.caller",
            headerName: "Caller",
            initialWidth: 120,
            type: TextFilter
          },
          {
            field: "order.declaredValue",
            tooltipField: "order.declaredValue",
            headerName: "Declared Value",
            type: NumberFilter,
            initialWidth: 150,
            initialHide: true
          },
          {
            field: "manifestSequence",
            tooltipField: "manifestSequence",
            headerName: "Sequence #",
            initialWidth: 120,
            type: NumberFilter,
            initialHide: true
          },
          {
            field: "see",
            headerName: "Contact",
            initialWidth: 150,
            cellRenderer: ContactCellRenderer,
            valueGetter: ContactValueGetter,
            initialHide: true,
            ...NotSortAndFilterColdef
          },
          {
            field: "order.notes",
            tooltipField: "order.notes",
            headerName: "Order Notes",
            initialWidth: 150,
            initialHide: true,
            ...NotSortAndFilterColdef
          },
          {
            field: "note",
            tooltipField: "note",
            headerName: "Stop Notes",
            initialWidth: 150,
            initialHide: true,
            ...NotSortAndFilterColdef
          },
          {
            field: "order.customer.notes",
            tooltipField: "order.customer.notes",
            headerName: "Customer Notes",
            initialWidth: 150,
            initialHide: true,
            ...NotSortAndFilterColdef
          },
          {
            colId: "details",
            headerName: "Details",
            cellRenderer: DetailsCellRenderer,
            ...NotSortAndFilterColdef
          }
        ] as ColDef[],
      [DateTimeValueGetter, colorColDefs, getDistinctValues, onManifestCellClicked]
    );

    const getRowId = useCallback((params) => params.data.jobStopId.toString(), []);

    const handleGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    const datasourceFailCallback = useCallback((error: ApolloError | undefined) => {
      setDatasourceFailed(error);
    }, []);

    const getColorIndicator = useCallback(
      async (stop: AgStop) => {
        if (!ruleContextState.loading && ruleContextState.rules && stop?.manifest) {
          const colors: string[] = [];

          const engine = ruleContextState.getConfiguredEngine(
            {manifest: stop.manifest, stop: stop},
            ruleContextState.rules.filter((rule) => rule.conditions.name !== ConditionNames.MANIFESTONLY)
          );

          if (engine === undefined) return;

          const results = await engine.run();

          if (results.failureEvents.length > 0) {
            console.debug("Rules engine failure events", results.failureEvents, results.failureResults);
          }

          if (results.events.length > 0) {
            colors.push(results.events[0].params?.color);
          }

          stop.indicatorColor = ruleContextState.colorRank
            ? getHighestRankedColor(colors, ruleContextState.colorRank)
            : undefined;
          console.debug("Assigning indicator color to row", stop.indicatorColor);
        }
      },
      [ruleContextState]
    );

    const buildIndicatorColors = useCallback(
      async (params: IServerSideGetRowsParams, newRows: AgStop[]) => {
        const promises = newRows.map(async (incomingRow) => {
          await getColorIndicator(incomingRow);
        });

        const results = await Promise.allSettled(promises);

        results.forEach((result) => {
          if (result.status === "rejected") {
            console.warn("Error getting color indicator", result.reason);
          }
        });
      },
      [getColorIndicator]
    );

    const sortAndFilterByColors = useCallback(
      async (params: IServerSideGetRowsParams, newRows: AgStop[], res: ApolloQueryResult<any>) => {
        if (filterAndSortByColorsEnabled) {
          const {sortModel, filterModel} = params.request;
          const filterByColors = filterModel?.indicatorColor;
          const sortByColors = sortModel.find((item) => item.colId === "indicatorColor");
          if (filterByColors) {
            filterByColors.values[filterByColors.values.findIndex((item: string) => item === "No Color")] = undefined;
            newRows.forEach((row, index) => {
              if (!filterByColors.values.includes(row.indicatorColor)) {
                (newRows[index] as ExtendedAgStop).hide = true;
              }
            });
          }
          if (sortByColors) {
            const sortDirection = sortByColors.sort === "asc" ? 1 : -1;
            newRows.sort((a, b) => {
              return (
                (getColorPriority(ruleContextState.rules, a.indicatorColor) -
                  getColorPriority(ruleContextState.rules, b.indicatorColor)) *
                sortDirection
              );
            });
          }
          const hideRows = newRows.filter((row) => (row as ExtendedAgStop).hide);

          hideRows.forEach((hideRow) => {
            const hideRowIndex = newRows.findIndex((row) => row.jobStopId === hideRow.jobStopId);
            newRows.splice(hideRowIndex, 1);
            newRows.push(hideRow);
          });

          if (res.data.searchStops.total > 100) {
            const warningMsg = getWarningMessage(Boolean(sortByColors), Boolean(filterByColors));
            setWarningMessage(warningMsg);
          } else {
            setWarningMessage(undefined);
          }
        }
      },
      [filterAndSortByColorsEnabled, ruleContextState.rules]
    );

    const handleGlobalSearch = useCallback(
      (query: string) => {
        if (gridReady) {
          if (gridContext.globalQuery != query) {
            setGridContext({...gridContext, globalQuery: query});
          }
        }
      },
      [gridContext, gridReady]
    );

    const onToggleToolPane = useCallback(
      (key: string) => {
        if (gridReady) {
          if (gridRef.current!.api.getOpenedToolPanel() === key) {
            gridRef.current!.api.closeToolPanel();
          } else {
            gridRef.current!.api.openToolPanel(key);
          }
        }
      },
      [gridReady]
    );

    const updateColumn = useCallback(
      (e: ColumnMovedEvent<Stop> | SortChangedEvent<Stop> | ColumnVisibleEvent<Stop>) => {
        const {source, columnApi} = e;
        const previousColumnState = extractJsonPref(
          userPreferences,
          UserPreferences.assignedStopsGridColumnState
        )?.value;

        if (!ALLOWED_SAVED_PREFERENCE_SOURCES.includes(source)) {
          console.debug("reverted visible state");
          const revertVisibleState: ColumnState[] = getArrayFromField(previousColumnState).map(
            ({colId, hide}: ColumnState) => ({
              colId,
              hide
            })
          );
          columnApi.applyColumnState({
            state: revertVisibleState
          });
        }

        const newColumnState = columnApi.getColumnState();
        if (gridReady && !datasourceFailed && !isEqual(previousColumnState, newColumnState) && !initializing) {
          setUserPref({
            variables: {
              name: UserPreferences.assignedStopsGridColumnState,
              input: createJsonPref(newColumnState, true)
            }
          }).then(() => {
            userPrefsQueryRefetch?.();
          });
        }
      },
      [userPreferences, gridReady, datasourceFailed, initializing, setUserPref, userPrefsQueryRefetch]
    );

    const onColumnMoved = useCallback(
      (e: ColumnMovedEvent<Stop>) => {
        if (!e.finished || e.source === "api") return;
        updateColumn(e);
      },
      [updateColumn]
    );

    const onColumnSortChanged = useCallback(
      (e: SortChangedEvent<Stop>) => {
        if (e.source === "api") return;
        updateColumn(e);
      },
      [updateColumn]
    );

    const onRestoreDefaults = useCallback(
      (justRestoreFilters?: boolean) => {
        gridRef.current?.api.setFilterModel(null);
        const promises = [
          setUserPref({
            variables: {name: UserPreferences.assignedStopsGridFilterState, input: createJsonPref(null, true)}
          })
        ];
        if (!justRestoreFilters) {
          promises.push(
            setUserPref({
              variables: {name: UserPreferences.assignedStopsGridColumnState, input: createJsonPref(null, true)}
            })
          );
        }
        Promise.all(promises).then(() => {
          userPrefsQueryRefetch?.();
        });

        if (!justRestoreFilters) {
          gridRef.current?.columnApi.resetColumnState();
        }

        gridRef.current?.api.onFilterChanged();
      },
      [setUserPref, userPrefsQueryRefetch]
    );

    const onRemoveFilters = useCallback(() => {
      onRestoreDefaults(true);
    }, [onRestoreDefaults]);

    const sideBar = useMemo(
      () => ({
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressColumnSelectAll: true,
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true
            }
          },
          {
            id: "settings",
            labelDefault: "Settings",
            labelKey: "settings",
            iconKey: "menu",
            toolPanel: GridSettingsToolPanel,
            toolPanelParams: {
              onRestoreDefaults: onRestoreDefaults
            }
          }
        ],
        defaultToolPanel: "customStats"
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useImperativeHandle(ref, () => ({onSearch: handleGlobalSearch, onToggleToolPane: onToggleToolPane}));

    useEffect(() => {
      if (gridReady && userPreferences.length > 0 && initializing && !ruleContextState.loading) {
        const columnState = extractJsonPref(userPreferences, UserPreferences.assignedStopsGridColumnState);
        if (columnState.value) {
          gridRef.current!.columnApi.applyColumnState({state: columnState.value, applyOrder: true});
        }
        const filterState = extractJsonPref(userPreferences, UserPreferences.assignedStopsGridFilterState);
        const hasFilterState = filterState.value && Object.keys(filterState.value).length > 0;
        if (hasFilterState) {
          gridRef.current!.api.setFilterModel(filterState.value);
          activeFilterDialogRef.current?.onOpen();
        }

        setInitializing(false);
      }
    }, [gridReady, initializing, ruleContextState.loading, userPreferences]);

    const onColumnVisible = useCallback(
      (e: ColumnVisibleEvent<Stop>) => {
        if (e.source === "api") return;
        updateColumn(e);
        e.api.refreshServerSide();
      },
      [updateColumn]
    );

    const onFilterChanged = useCallback(
      (event: FilterChangedEvent<Stop>) => {
        setGridHasActiveFilters(Object.keys(event.api.getFilterModel()).length > 0);
        const filterState = extractJsonPref(userPreferences, UserPreferences.assignedStopsGridFilterState)?.value;
        const newFilterState = event.api.getFilterModel();
        if (!isEqual(filterState, newFilterState) && !initializing)
          try {
            setUserPref({
              variables: {
                name: UserPreferences.assignedStopsGridFilterState,
                input: createJsonPref(newFilterState, true)
              }
            }).then(() => {
              userPrefsQueryRefetch?.();
            });
          } catch (error) {
            console.debug(error);
          }
      },
      [initializing, setGridHasActiveFilters, setUserPref, userPreferences, userPrefsQueryRefetch]
    );

    useImperativeHandle(ref, () => ({onSearch: handleGlobalSearch, onToggleToolPane: onToggleToolPane}));

    useEffect(() => {
      if (gridReady && userPreferences.length > 0 && initializing && !ruleContextState.loading) {
        const columnState = extractJsonPref(userPreferences, UserPreferences.assignedStopsGridColumnState);
        if (columnState.value) {
          gridRef.current!.columnApi.applyColumnState({state: columnState.value, applyOrder: true});
        }
        const filterState = extractJsonPref(userPreferences, UserPreferences.assignedStopsGridFilterState);
        const hasFilterState = filterState.value && Object.keys(filterState.value).length > 0;
        if (hasFilterState) {
          gridRef.current!.api.setFilterModel(filterState.value);
        }
        setInitializing(false);
      }
    }, [gridReady, initializing, ruleContextState.loading, userPreferences]);

    useEffect(() => {
      let dipatchGroupIds = [];
      if (assignmentViewState.manifestFilters?.hasFilter(ManifestFilterType.DRIVER_GROUP)) {
        dipatchGroupIds = Array.from(
          assignmentViewState.manifestFilters.manifestFilters.get(ManifestFilterType.DRIVER_GROUP)?.value ?? []
        );
      } else if (dispatchStationState.hasSelectedDispatchStations()) {
        dipatchGroupIds = Array.from(dispatchStationState.selectedDispatchStations.values()).flatMap((ds) => {
          return Array.from(ds.driverGroups);
        });
      }
      if (dipatchGroupIds) {
        setGridContext({
          ...gridContext,
          globalFilter:
            dipatchGroupIds.length > 0
              ? {
                  or: Array.from(new Set(dipatchGroupIds)).map((id) => {
                    return {
                      manifest_dispatchGroupId: {eq: id}
                    } as StopFilter;
                  })
                }
              : undefined
        });
      } else {
        setGridContext({
          ...gridContext,
          globalFilter: undefined
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentViewState.manifestFilters, dispatchStationState, setGridContext]);

    useEffect(() => {
      if (gridReady) {
        setDatasource(
          createStopsServerSideDatasource({
            client: apolloClient,
            defaultFields: [
              "manifest.manifestStatus",
              "jobStopId",
              "stopType",
              "timeZone",
              "scheduledDateTime",
              "earlyDateTime",
              "lateDateTime",
              "arriveDateTime",
              "departDateTime",
              "podDateTime",
              "name",
              "address",
              "city",
              "state",
              "zip",
              "note",
              "see",
              "order.orderId",
              "order.service",
              "order.customer.notes",
              "order.notes",
              "driver.driverId"
            ],
            excludeFields: ["indicatorColor", "details", "completedTime"],
            onDatasourceFail: datasourceFailCallback,
            decorateResults: [buildIndicatorColors, sortAndFilterByColors],
            tokenExpiry: authState.expiresAt ? new Date(authState.expiresAt) : undefined
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apolloClient, gridReady, authState.expiresAt, datasourceFailCallback]);

    useEffect(() => {
      if (gridReady) {
        gridRef.current!.api.onFilterChanged();
      }
    }, [gridReady, gridContext]);

    const activeFilter: IActiveFilterDialogProps = {
      message:
        "To view all assigned stops, please clear any active filters in the table header that may be limiting your stop list.",
      title: "Active Filters Present",
      icon: <Icon icon="filter" color="green" size={21} />,
      actionButton: {
        label: "Remove Filter(s)",
        action: onRemoveFilters
      }
    };

    return (
      <Container>
        <ActiveFilterDialog ref={activeFilterDialogRef} {...activeFilter} />
        {warningMessage && (
          <WarningText style={{padding: "8px 4px"}}>
            <CircleWarningIcon />
            <Text ellipsize>{warningMessage}</Text>
          </WarningText>
        )}
        <GridContainer className="ag-theme-alpine">
          <AgGridReact<Stop>
            className={"stops-panel-grid"}
            ref={gridRef}
            sideBar={sideBar}
            onGridReady={handleGridReady}
            getRowId={getRowId}
            defaultColDef={DefaultColDef}
            columnDefs={columnDefs}
            context={gridContext}
            suppressCellFocus
            columnTypes={ColumnTypes}
            onColumnVisible={onColumnVisible}
            onFilterChanged={onFilterChanged}
            onSortChanged={onColumnSortChanged}
            onColumnMoved={onColumnMoved}
            enableCellTextSelection
            ensureDomOrder
            rowModelType={"serverSide"}
            serverSideDatasource={datasource}
            pagination
            paginationPageSize={100}
            cacheBlockSize={100}
            animateRows
            headerHeight={40}
            gridOptions={gridOptions}
            suppressColumnVirtualisation={true}
            maintainColumnOrder
            rowStyle={rowStyle}
            suppressMultiSort={true}
            suppressCsvExport
          />
        </GridContainer>
      </Container>
    );
  }
);

Grid.displayName = "grid";

export default Grid;

const rowStyle: RowStyle = {
  userSelect: "none",
  fontSize: "12px"
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const GridContainer = styled.div`
  flex: 1;
  font-family: Roboto;

  .ag-side-bar .ag-side-buttons {
    display: none;
  }

  .header-cell {
    color: #4b5563;
    padding: 0 12px;
  }

  .body-cell {
    font-size: 14px;
    padding: 0 12px;
    color: #111827;
  }

  .header-cell-color {
    color: #4b5563;
    padding: 0 0 0 4px !important;
  }

  .body-cell-color {
    padding: 0 4px !important;
  }

  .ag-header-cell-text {
    font-size: 12px;
    text-transform: uppercase;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    border-right: none;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border: none;
  }

  .text-blue {
    color: #2563eb;
  }

  .text-bold {
    font-weight: 500;
  }
`;

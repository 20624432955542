import styled from "@emotion/styled";
import {Button, Dialog, DialogBody, DialogFooter, Text} from "@blueprintjs/core";
import React, {Fragment} from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DialogBox = styled(Dialog)`
  box-sizing: border-box;
  background: #fcfcfc;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  width: 390px;
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  color: #ff6b00;
`;

const Body = styled(DialogBody)`
  background: transparent;

  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 32px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 17px;
  padding-top: 17px;
`;

const Title = styled(Text)`
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-left: 19px;
`;

const Footer = styled(DialogFooter)`
  background: transparent;
  border-radius: 0 0 0 0;
  border-top: 0;
`;

export const ActionButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: self-end;
  padding: 6px 14px;
  gap: 7px;
  background: #ffffff;
  border-radius: 4px;
  border: none !important;
  box-shadow: 0px 1px 1px 0px #00000040 !important;

  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  :hover {
    opacity: 0.9;
  }
`;

export interface IManifestDetailsActionConfirmationProps {
  icon?: JSX.Element;
  testIdPrefix: string;
  isVisible: boolean;
  header: string;
  body: string | JSX.Element;
  yesText?: string | JSX.Element;
  noText?: string | JSX.Element;
  yesTextColor?: string;
  handleYesClicked?(): void;
  handleNoClicked?(): void;
  handleClose?(): void;
  useFooter?: boolean;
}

export const ConfirmationDialog = ({
  icon,
  testIdPrefix,
  isVisible,
  header,
  body,
  yesText,
  noText,
  yesTextColor: confirmTextColor,
  useFooter = true,
  handleYesClicked,
  handleNoClicked,
  handleClose
}: IManifestDetailsActionConfirmationProps) => {
  const actions = (
    <Fragment>
      <ActionButton data-testid={`${testIdPrefix}-dialog-no-button`} text={noText ?? "No"} onClick={handleNoClicked} />
      <ActionButton
        data-testid={`${testIdPrefix}-dialog-yes-button`}
        text={yesText ?? "Yes"}
        style={{color: confirmTextColor ?? "#fa545e"}}
        onClick={handleYesClicked}
      />
    </Fragment>
  );
  const title = (
    <Header>
      {icon ?? <HeaderIcon size={"xl"} icon={solid("circle-exclamation")} />}
      <Title>{header}</Title>
    </Header>
  );
  return (
    <DialogBox
      data-testid={`${testIdPrefix}-dialog-box`}
      title={title}
      isOpen={isVisible}
      onClose={handleClose ?? handleNoClicked}
      className={"manifest-details-action-dialog"}
    >
      <Body>{body}</Body>
      {useFooter && <Footer actions={actions}></Footer>}
    </DialogBox>
  );
};

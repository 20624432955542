import {RowDragEvent, RowDragMoveEvent, ValueFormatterFunc} from "@ag-grid-community/core";
import {ManifestStopEnhanced} from "./ManifestDetailsV2";
import {
  isBothStop,
  isDeliveryOrBothStop,
  isDeliveryStop,
  isInCompleted,
  isPickupOrBothStop,
  isPickupStop,
  jobStopStatusToString
} from "../../../services/ManifestStopService";

let animationFrame: number | undefined = undefined;

type ScrollAction = "start" | "stop";

//Every 100 rows will take 7 seconds to scroll from bottom to top.
export const AverageDuration = 7000;
export const AverageRows = 100;

export const calculateAverageScrollDurationByTotalRows = (totalRows: number) => {
  return (AverageDuration * totalRows) / AverageRows;
};

export const calculateRemainingScrollDuration = (
  scrollTop: number,
  scrollHeight: number,
  averageScrollDuration: number
) => {
  return averageScrollDuration * (scrollTop / scrollHeight);
};

export const handleScrollTop = (type: ScrollAction, totalRows?: number) => {
  if (type === "start" && totalRows) {
    const scrollElements = document.querySelectorAll(".ag-body-vertical-scroll-viewport");
    const averageScrollDurationByTotalRows = calculateAverageScrollDurationByTotalRows(totalRows);
    scrollElements.forEach((element) => {
      const currentScrollTop = element.scrollTop;
      const currentScrollHeight = element.scrollHeight;
      const remainingScrollDuration = calculateRemainingScrollDuration(
        currentScrollTop,
        currentScrollHeight,
        averageScrollDurationByTotalRows
      );
      const startTime = performance.now();

      function animateScroll(timestamp: number) {
        const elapsed = timestamp - startTime;
        const progress = elapsed / remainingScrollDuration;

        const newScrollTop = currentScrollTop - currentScrollTop * progress;

        element.scrollTo({
          top: newScrollTop,
          behavior: "auto"
        });

        if (elapsed >= remainingScrollDuration) {
          animationFrame = undefined;
          return;
        }

        animationFrame = requestAnimationFrame(animateScroll); // Continue scrolling
      }

      // Start the animation
      animationFrame = requestAnimationFrame(animateScroll);
    });
  } else {
    if (animationFrame) {
      cancelAnimationFrame(animationFrame);
      animationFrame = undefined;
    } else {
      return;
    }
  }
};

export const checkRowMoveAndDoFunction = (
  event: RowDragEvent,
  func: (arg: ScrollAction, totalRows?: number) => void,
  totalRows?: number
) => {
  if (event.type === "rowDragMove") {
    func("stop");
  } else if (event.type === "rowDragLeave" && event.vDirection === "up" && totalRows) {
    func("start", totalRows);
  } else {
    return;
  }
};

export const checkNoSpaceBetweenSelectedRows = (rowIndexes: number[]) => {
  for (let index = 0; index < rowIndexes.length - 1; index++) {
    if (rowIndexes[index] + 1 !== rowIndexes[index + 1]) {
      return false;
    }
  }
  return true;
};

export const getUpdatedStops = (updatedStops: Map<number, ManifestStopEnhanced>) => {
  const updatedStopsArray = [...updatedStops.values()]
    .sort((a, b) => {
      if (a.optimizedSequence && b.optimizedSequence) return a.optimizedSequence - b.optimizedSequence;
      if (a.optimizedSequence) return -1;
      if (b.optimizedSequence) return 1;
      return 0;
    })
    .map((stop, index) => {
      if (stop.optimizedSequence === undefined) {
        stop.optimizedSequence = index + 1;
      }
      return stop;
    });
  return updatedStopsArray;
};

export const getDraggingStopsList = (
  event: RowDragMoveEvent,
  workingStops: ManifestStopEnhanced[],
  setFunction: React.Dispatch<React.SetStateAction<ManifestStopEnhanced[] | undefined>>
) => {
  const draggingRowsData = event.nodes.map((node) => node.data) as ManifestStopEnhanced[];
  const overRowData = event.overNode?.data as ManifestStopEnhanced;
  if (event.nodes[0].rowIndex === event.overIndex) {
    return;
  }

  const _Items = [...(workingStops ?? [])];

  // Calculate the index where the dragging rows will be inserted
  let insertIndex = _Items.findIndex(
    (stop) => stop?.manifestSequence === overRowData?.manifestSequence && stop.jobStopId === overRowData.jobStopId
  );

  if (insertIndex === -1) {
    insertIndex = _Items.length;
  }

  // Remove dragging rows from their original positions
  draggingRowsData.forEach((draggingRow) => {
    const draggingRowIndex = _Items.findIndex(
      (stop) => stop?.manifestSequence === draggingRow?.manifestSequence && stop.jobStopId === draggingRow.jobStopId
    );
    if (draggingRowIndex !== -1) {
      _Items.splice(draggingRowIndex, 1);
    }
  });

  // Insert dragging rows at the calculated insertIndex
  _Items.splice(insertIndex, 0, ...draggingRowsData);

  setFunction(_Items);
};

export const jobStopStatusValueFormatter: ValueFormatterFunc<{value: string}> = ({value}) =>
  jobStopStatusToString(value);

export const getManifestWrongOrderJobNumbers = (stops: ManifestStopEnhanced[]) => {
  const jobStops: Record<string, ManifestStopEnhanced[]> = {};
  const wrongOrderJobs = new Set<string>();

  for (const stop of stops) {
    const jobNumber = stop.job.jobNumber;
    if (!jobStops[jobNumber]) {
      jobStops[jobNumber] = [];
    }
    jobStops[jobNumber].push(stop);
  }

  for (const jobNumber in jobStops) {
    const jobNumberStops = jobStops[jobNumber];
    const activeStops = jobNumberStops.filter(isInCompleted);
    const isHasActiveDeliveryOrBothStops = activeStops.some(isDeliveryOrBothStop);
    if (!isHasActiveDeliveryOrBothStops) {
      continue;
    }

    activeStops.forEach((item, index) => {
      const previousActiveStops = activeStops.slice(0, index);
      const followingActiveStops = activeStops.slice(index + 1);
      if (index === activeStops.length - 1) {
        if (isPickupStop(item)) {
          wrongOrderJobs.add(jobNumber);
        }
        if (
          isBothStop(item) &&
          previousActiveStops.some(isDeliveryStop) &&
          !followingActiveStops.some(isDeliveryStop)
        ) {
          wrongOrderJobs.add(jobNumber);
        }
      } else if (isDeliveryStop(item) || isBothStop(item)) {
        const isHasSupportedPickupStop = previousActiveStops.some(isPickupOrBothStop);
        if (!isHasSupportedPickupStop && followingActiveStops.some(isPickupStop)) {
          wrongOrderJobs.add(jobNumber);
        }
      }
    });
  }

  return Array.from(wrongOrderJobs);
};
